import 'isomorphic-fetch';
import { postImage } from 'lib/s3ImageUploadService';

import {
  formatProductPrice,
  getDisplayPrice,
} from 'components/Editor/Escribir/lib/helpers';
import { isValidUrl } from 'lib/helpers';

export const getFormattedPrice = (price) => {
  let formattedPrice = price;
  if (isNaN(parseFloat(formattedPrice))) {
    const priceData = formatProductPrice(formattedPrice);
    formattedPrice = priceData.price;
  }
  return formattedPrice;
};

export const getNumberFromPrice = (price, isDollarSite) => {
  if (isNaN(Number(price))) {
    if (isDollarSite) {
      price = price.replace(',', '');
    } else {
      price = price.replace('.', '').replace(',', '.');
    }
    return Number(price);
  }
  return price;
};

export const renderPrice = ({ price, currency, primePrice = null }) => {
  if (window.flags.useAmazonPrimePrice && null !== primePrice) {
    if (isNaN(parseFloat(primePrice))) {
      const priceData = formatProductPrice(primePrice);
      primePrice = priceData.price;
      currency = priceData.currency;
    }
    return getDisplayPrice(primePrice, currency);
  }

  if (isNaN(parseFloat(price))) {
    const priceData = formatProductPrice(price);
    price = priceData.price;
    currency = priceData.currency;
  }
  return getDisplayPrice(price, currency);
};

export const uploadImageViaUrl = async (siteName: string, imageUrl: string) => {
  const data = new FormData();
  data.append('url', imageUrl);

  return await postImage(siteName, data);
};

export const hasManualProduct = (source) => {
  return source.some((product) => !product.isApiProduct);
};

export const hasApiProduct = (source) => {
  return source.some((product) => product.isApiProduct);
};

export const filterManualProducts = (source, showRowHighlight) => {
  return source
    .filter((product) => !product.isApiProduct)
    .map((product) => ({
      ...product,
      showRowHighlighted:
        showRowHighlight &&
        (product.url !== '' ||
          product.linkText.trim() !== 'PVP en' ||
          product.price !== ''),
    }));
};

export const showWarningOnSearchAgain = (
  section,
  isTitleAddedManually,
  isImageAddedManually,
  productImage
) => {
  return (
    hasApiProduct(section.source) ||
    (!isTitleAddedManually && '' !== section.title) ||
    (!isImageAddedManually && '' !== productImage && '' !== productImage.src)
  );
};

export const showSearchedStatus = (section, isAddedManually) => {
  return (
    'searchAgain' === section.searchStatus &&
    isAddedManually &&
    !hasApiProduct(section.source)
  );
};

export const keepShowingWarningOnDeselect = (
  source,
  isTitleAddedManually,
  isImageAddedManually
) => {
  return (
    source.length > 0 &&
    (hasApiProduct(source) || !(isTitleAddedManually && isImageAddedManually))
  );
};

export const hasOnlyManualOfferWithNoProductSearched = (mode, section) => {
  return (
    'edit' === mode &&
    !section.inputText &&
    section.source?.length > 0 &&
    !hasApiProduct(section.source)
  );
};

export const getSearchStatus = (showWarning, searchStatus, ecomProducts) => {
  if (showWarning) {
    return searchStatus;
  }
  if (ecomProducts.length > 0) {
    return 'searched';
  }
  return 'initial';
};

export const showSavePivotError = (source, title, productImage) => {
  let showSelectedError = source.reduce((acc, product) => {
    const { linkText = '' } = product;
    return (
      acc ||
      ('' === linkText.trim() && product.hasOwnProperty('updatePrice')) ||
      !product.price ||
      (product.hasOwnProperty('isApiProduct') &&
        !product.isApiProduct &&
        !isValidUrl(product.url))
    );
  }, false);
  return showSelectedError || !productImage?.src || !title.trim();
};

export const getFinalSearchStatus = (searchStatus, saveSearch) => {
  if ('searchAgain' !== searchStatus) {
    if (saveSearch) {
      return searchStatus;
    }
    return 'initial';
  }
  return 'searched';
};

export const selectedProductsAsin = (source) => {
  return source
    .filter(
      ({ isApiProduct, isLegacySaleOffer }) =>
        isApiProduct && !isLegacySaleOffer
    )
    .map(({ asin }) => asin);
};

export const ecomProductsAsin = (ecomProducts) => {
  return ecomProducts.map(({ asin }) => asin);
};

export const showHighlight = (source, ecomProducts, isSearchedAgain) => {
  return (
    isSearchedAgain &&
    selectedProductsAsin(source).some((asin) =>
      ecomProductsAsin(ecomProducts).includes(asin)
    )
  );
};

export const isAllManualAddedProducts = (source) => {
  return source.every(({ isApiProduct }) => !isApiProduct);
};

export const hasFilledManualProduct = (source) => {
  return source.some((product) => {
    return (
      product.showRowHighlighted &&
      !(
        product.url === '' &&
        product.linkText.trim() === '' &&
        product.price === ''
      )
    );
  });
};
