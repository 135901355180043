import React from 'react';
import styles from './FieldSize.module.css';

type Props = {
  children: any,
  size:
    | 'preset36'
    | 'preset64'
    | 'preset88'
    | 'preset98'
    | 'preset100'
    | 'preset106'
    | 'preset112'
    | 'preset122'
    | 'preset132'
    | 'preset148'
    | 'preset156'
    | 'preset168'
    | 'preset172'
    | 'preset192'
    | 'preset190'
    | 'preset214'
    | 'preset236'
    | 'preset240'
    | 'preset308'
    | 'preset328'
    | 'preset360'
    | 'preset706'
    | 'preset154'
    | 'preset284',
};

export const FieldSize = ({ children, size = 'preset148' }: Props) => {
  const sizeClassName = [];

  if ('preset360' === size) {
    sizeClassName.push(styles.preset360);
  } else if ('preset240' === size) {
    sizeClassName.push(styles.preset240);
  } else if ('preset36' === size) {
    sizeClassName.push(styles.preset36);
  } else if ('preset106' === size) {
    sizeClassName.push(styles.preset106);
  } else if ('preset154' === size) {
    sizeClassName.push(styles.preset154);
  } else if ('preset328' === size) {
    sizeClassName.push(styles.preset328);
  } else if ('preset64' === size) {
    sizeClassName.push(styles.preset64);
  } else if ('preset168' === size) {
    sizeClassName.push(styles.preset168);
  } else if ('preset156' === size) {
    sizeClassName.push(styles.preset156);
  } else if ('preset88' === size) {
    sizeClassName.push(styles.preset88);
  } else if ('preset98' === size) {
    sizeClassName.push(styles.preset98);
  } else if ('preset100' === size) {
    sizeClassName.push(styles.preset100);
  } else if ('preset112' === size) {
    sizeClassName.push(styles.preset112);
  } else if ('preset192' === size) {
    sizeClassName.push(styles.preset192);
  } else if ('preset308' === size) {
    sizeClassName.push(styles.preset308);
  } else if ('preset706' === size) {
    sizeClassName.push(styles.preset706);
  } else if ('preset214' === size) {
    sizeClassName.push(styles.preset214);
  } else if ('preset122' === size) {
    sizeClassName.push(styles.preset122);
  } else if ('preset236' === size) {
    sizeClassName.push(styles.preset236);
  } else if ('preset190' === size) {
    sizeClassName.push(styles.preset190);
  } else if ('preset284' === size) {
    sizeClassName.push(styles.preset284);
  } else if ('preset304' === size) {
    sizeClassName.push(styles.preset304);
  } else if ('preset166' === size) {
    sizeClassName.push(styles.preset166);
  } else if ('preset130' === size) {
    sizeClassName.push(styles.preset130);
  } else {
    sizeClassName.push(styles.preset148);
  }

  return <div className={sizeClassName.join(' ')}>{children}</div>;
};
