import { base, auth, signInWithCustomToken } from 'lib/firebase';
import isoFetch from 'isomorphic-fetch';

import { convertTo1DArray, getPostStatus } from './helpers';
import { submitRequest } from 'lib/service';
import {
  POST_TYPE_NORMAL,
  SUBTYPE_LONGFORM,
  POST_TYPE_BRAND_ARTICLE,
  POST_TYPE_LANDING,
  POST_TYPE_BRANDED,
  LONGFORM_POST_TYPES,
} from 'lib/constants';
import {
  currentHour,
  getCurrentUtcTime,
  convertDateToUtc,
} from './momentHelper';
import {
  getImageExtension,
  queryBuilder,
  isEvergreenCategoryMapped,
} from 'lib/helpers';

import { Video } from 'lib/flowTypes';

const version = process.env.REACT_APP_version || '1';

export const createPost = (initialData) => {
  return base.post('posts/' + initialData.id, {
    data: initialData,
  });
};

function delay(ms) {
  return new Promise(function (resolve) {
    return setTimeout(resolve, ms);
  });
}

let tries = 0;

export const getSiteConfig = async (site = '') => {
  try {
    let queries = {
      orderByChild: 'host',
      equalTo: window.location.host,
    };

    if ('' !== site) {
      queries = {
        orderByChild: 'site_name',
        equalTo: site,
      };
    }
    const data = await base.fetch('config', {
      asArray: true,
      queries: queries,
    });
    if (undefined === data && tries <= 2) {
      tries++;
      await delay(500);
      return getSiteConfig(site);
    } else {
      return data[0];
    }
  } catch (error) {
    throw new Error('host not found in Config');
  }
};

export const getPost = (postname) => {
  return base.fetch('posts/' + postname, {});
};

export const listenToPost = (postname, receivePost) => {
  return base.listenTo('posts/' + postname, {
    context: {},
    then(post) {
      receivePost(post);
    },
  });
};

export const removeBinding = (dbRef) => {
  base.removeBinding(dbRef);
};

export const listenToPostRepostSites = (
  postname,
  updatePostRepostBlogNames
) => {
  base.listenTo('posts/' + postname + '/publishData/postRepostBlogNames', {
    context: {},
    then(repostBlogNames) {
      updatePostRepostBlogNames(repostBlogNames);
    },
  });
};

export const listenToPostReindexNames = (postname, updatePostReindexNames) => {
  base.listenTo('posts/' + postname + '/reindexedNames', {
    context: {},
    then(reindexedNames) {
      updatePostReindexNames(reindexedNames);
    },
  });
};

export const listenToPostTitle = (postname, updatePostTitle) => {
  base.listenTo('posts/' + postname + '/sections/0/text', {
    context: {},
    then(title) {
      updatePostTitle(title);
    },
  });
};

export const listenToPostActiveUsers = (postname, updateActiveUsers) => {
  base.listenTo('posts/' + postname + '/online', {
    context: {},
    then(online) {
      updateActiveUsers(online);
    },
  });
};

export const updatePost = async (postname, publishData) => {
  return await base.update('posts/' + postname + '/publishData', {
    data: { ...publishData },
  });
};

export const updatePostInFirebase = async (firebaseId: String, data: {}) =>
  await base.update(`posts/${firebaseId}`, { data });

export const savePostFromEscribirPage = async (state, publishData) => {
  let firebaseData = {
    id: state.id,
    title: state.title,
    subTitle: state.subTitle || '',
    sections: state.fields,
    maxId: state.maxId,
    meta: state.meta,
    postType: state.postType,
    republishDate: state.republishDate || '',
    productsCreated: state.productsCreated,
    isContentUpdated: state.isContentUpdated,
    crop: state.crop || '',
    primaryImage: state.primaryImage || '',
    twitter: state.twitter || '',
    isTocExists: state.isTocExists || false,
  };
  if (state.currentUser) {
    firebaseData.lastUpdatedBy = state.currentUser;
    firebaseData.lastUpdatedAt = getCurrentUtcTime('DD/MM/YYYY HH:mm');
  }
  if (publishData) {
    firebaseData.publishData = {
      postId: publishData.id,
      postHash: publishData.post_hash,
    };
  }

  return await updatePostInFirebase(state.id, firebaseData);
};

export const savePost = (state) => {
  let firebaseData = {
    id: state.id,
    title: state.title,
    subTitle: state.subTitle || '',
    sections: state.fields,
    maxId: state.maxId,
    blogName: state.blogName,
    meta: state.meta,
    status: state.status,
    user_id: state.userId,
    user_name: state.userName,
    isSensitive: state.isSensitive,
    specialPost: state.specialPost,
    publishData: {
      postDate: state.publishedDate || null,
      publishRegion: state.publishRegion,
      postRepostBlogNames: state.postRepostBlogNames,
      postId: state.postId,
      postHash: state.postHash,
    },
    crop: state.crop,
    tags: state.tags,
    category: state.category,
    postCategories: state.postCategories,
    ampVisibility: state.ampVisibility,
    postVisibility: state.postVisibility,
    commentStatus: state.commentStatus,
    facebook: state.facebook,
    twitter: state.twitter,
    postType: state.postType,
    isNanomedio: state.isNanomedio,
    lastUpdatedBy: state.lastUpdatedBy,
    lastUpdatedAt: state.lastUpdatedAt,
    updateModifiedGmt: state.updateModifiedGmt,
    rating: state.rating,
    productsCreated: state.productsCreated,
    categoryNicename: state.categoryNicename || null,
    reindexedNames: state.reindexedNames || [],
    isContentUpdated: state.isContentUpdated,
    primaryImage: state.primaryImage || '',
    affiliationId: state.affiliationId,
    skinCss: state.skinCss,
    isReindexScheduled: state.isReindexScheduled || false,
    isEvergreen: state.isEvergreen || false,
    contentType: state.contentType || { id: -1 },
    juegos: state.juegos || [],
    platforms: state.platforms || [],
    hardware: state.hardware || [],
    others: state.others || [],
    postUrlPrefix: state.postUrlPrefix || null,
    redirectId: state.redirectId || '',
    redirectType: state.redirectType || null,
    redirectTitle: state.redirectTitle || '',
    redirectSite: state.redirectSite || '',
    redirectSiteURL: state.redirectSiteURL || '',
    redirectURL: state.redirectURL || '',
    redirectStatus: state.redirectStatus || '',
    redirectFirebaseId: state.redirectFirebaseId || '',
    redirectFieldValue: state.redirectFieldValue || '',
    prevPublishedRedirectId: state.prevPublishedRedirectId || '',
    hasUnpublishedRedirectChanges: state.hasUnpublishedRedirectChanges || false,
  };

  if (version !== 1) {
    firebaseData['bioVisibility'] = state.bioVisibility || null;
  }

  return updatePostInFirebase(state.id, firebaseData);
};

export const savePostsList = (state, blogName) => {
  if (state.postType === 'longform' || state.postType === 'brandedLongform') {
    let blogStatus = blogName + '_' + state.status;
    let listData = {
      id: state.id,
      title: state.title || 'Título...',
      status: state.status,
      user_id: state.userId,
      blog_name: blogName,
      user_status: blogName + '_' + state.userId + '_' + state.status,
      blog_status: blogStatus,
      blog_post_type: blogStatus + '_' + mapPostType(state.postType),
    };

    base.post('posts_list/' + state.id, {
      data: listData,
    });
  }
};

export const mapPostType = (postType) => {
  const normalTypes = [POST_TYPE_NORMAL, POST_TYPE_LANDING];
  return normalTypes.includes(postType) ? 'normal' : 'club';
};

const getPostSubType = (postType) =>
  LONGFORM_POST_TYPES.includes(postType) ? SUBTYPE_LONGFORM : '';

const getBioVisibleBackendValues = (value) => {
  if (value === 1) {
    return true;
  }
  if (value === 2) {
    return false;
  }
  return null;
};

const getBackendApiParameters = (state, method, extraParameteres) => {
  let backendParameters;

  if ('POST' === method) {
    backendParameters = {
      firebase_id: state.id,
      post_type: mapPostType(state.postType),
    };
  }

  if (
    'draft' === state.status &&
    [
      POST_TYPE_NORMAL,
      POST_TYPE_BRANDED,
      POST_TYPE_BRAND_ARTICLE,
      POST_TYPE_LANDING,
    ].includes(state.postType)
  ) {
    return {
      post_title: state.title,
      post_author: state.userId,
      post_status: 'draft',
      ...backendParameters,
    };
  }

  const extension = getImageExtension(state.primaryImage);
  const primaryImage = state.primaryImage?.replace(
    `original.${extension}`,
    `image_dimension.${extension}`
  );

  const isEvergreen =
    state.isEvergreen ||
    isEvergreenCategoryMapped(
      state.blogName,
      state.categoryNicename,
      state.postCategories
    );
  const { seo, sponsor, ...meta } = state.meta;
  // eslint-disable-next-line
  const { isSponsored = false, ...sponsorFields } = sponsor;

  backendParameters = {
    post_category: state.category,
    post_title: state.title,
    post_subtitle: state.subTitle === '<p><br></p>' ? '' : state.subTitle,
    comment_status: state.commentStatus,
    post_content: JSON.stringify(state.fields),
    post_excerpt: JSON.stringify({ sponsor: sponsorFields, ...meta }),
    post_date_gmt: convertDateToUtc(state.publishedDate),
    countries: state.publishRegion.join(','),
    post_status: getPostStatus(state.status, state.publishedDate),
    post_author: state.userId,
    primary_image: primaryImage,
    is_sensitive: ~~state.isSensitive,
    long_post: Number(!!state.specialPost),
    amp_visibility: Number(!!state.ampVisibility),
    post_abstract: seo.description.trim(),
    post_extended_title: seo.title.trim(),
    twitter_sharing: state.twitter || '',
    fb_sharing: state.facebook || '',
    post_visibility: Number(!!state.postVisibility),
    disclaimer: POST_TYPE_BRAND_ARTICLE === state.postType ? sponsor.name : '',
    update_modified_gmt: Number(!!state.updateModifiedGmt),
    rating: state.rating || 0,
    tags: convertTo1DArray(state.tags),
    sub_categories: convertTo1DArray(state.postCategories, state.category),
    affiliate_id: state.affiliationId,
    css_skin: state.skinCss,
    is_evergreen: ~~isEvergreen,
    redirect_id: state.redirectId || null,
    redirect_site: state.redirectSite ? state.redirectSite : state.blogName,
    redirect_type: state.redirectType,
    ...backendParameters,
    ...extraParameteres,
  };

  if (version !== 1) {
    backendParameters['author_bio_visible'] = getBioVisibleBackendValues(
      state.bioVisibility
    );
  }
  const postSubtype = getPostSubType(state.postType);
  if ('' !== postSubtype) {
    backendParameters = {
      post_subtype: postSubtype,
      ...backendParameters,
    };
  }
  // To fix issue where products are not created on backend if post is saved quickly twice
  if ('draft' !== state.post_status && 1 === state.productsCreated) {
    backendParameters = {
      products_created: 1,
      ...backendParameters,
    };
  }

  return backendParameters;
};

export const submitCrossPostToBackend = (data, adminUrl, site) => {
  let method = 'POST';
  let url = `${adminUrl}/api/post?site=${site}`;
  return submitRequest(url, data, method);
};

export const submitPostToBackend = (state, adminUrl, extraParameteres = {}) => {
  let method = 'POST';
  let postId = '';
  if (state.postId !== undefined && state.postId !== '') {
    method = 'PUT';
    postId = state.postId;
  }
  const backendData = getBackendApiParameters(state, method, extraParameteres);

  return submitPostAttributesToBackend(backendData, adminUrl, postId);
};

export const submitPostAttributesToBackend = (
  backendData,
  adminUrl,
  postId = ''
) => {
  let url = `${adminUrl}/api/post`;
  let method = 'POST';
  if ('undefined' !== typeof postId && '' !== postId) {
    method = 'PUT';
    url += `/${postId}`;
  }

  return submitRequest(url, backendData, method);
};

export const republishPostNow = async (
  adminUrl,
  postId,
  postName,
  republishDisclaimerVisibility = true
) => {
  const backendData = {
    republish_date: getCurrentUtcTime('YYYY-MM-DD HH:mm:ss'),
    republish_disclaimer_visibility: republishDisclaimerVisibility,
  };
  await submitPostAttributesToBackend(backendData, adminUrl, postId);

  updatePostInFirebase(postName, {
    republishDate: currentHour(),
    lastUpdatedAt: getCurrentUtcTime(),
    commentStatus: 'open',
  });
};

export const republishSchedule = async (
  adminUrl,
  postId,
  date,
  postName,
  republishDisclaimerVisibility
) => {
  const backendData = {
    date: date,
    republish_interval: 0,
    republishDisclaimerVisibility,
  };
  const url = `${adminUrl}/republish/schedule/${postId}`;

  const data = await submitRequest(url, backendData);

  if ('already_scheduled' !== data.status) {
    updatePostInFirebase(postName, {
      republishDate: date,
      lastUpdatedAt: getCurrentUtcTime(),
    });
  }

  return data;
};

export const getUserDetails = async (adminUrl) => {
  try {
    const response = await isoFetch(`${adminUrl}/api/users/currentUser.json`, {
      credentials: 'include',
    });
    return response.json();
  } catch (err) {
    throw new Error('NOT_LOGGED_IN');
  }
};

export const signInWithToken = async (token: string) =>
  await signInWithCustomToken(auth, token);

export const getPostVersions = async (postName: string) =>
  await base.fetch('post_versions/' + postName, {
    asArray: true,
    queries: {
      limitToLast: 10,
    },
  });

export const getScheduledReindexPosts = async (siteName) =>
  await base.fetch('dashboard_posts', {
    asArray: true,
    queries: {
      orderByChild: 'site',
      equalTo: siteName,
    },
  });

export const restorePostVersion = async (
  postName: String,
  version: String,
  userId: Number
) => {
  const functionUrl = `${process.env.REACT_APP_updateVersionUrl}/handleRestore`;
  const params = `?postName=${postName}&version=${version}&userId=${userId}`;
  const token = await auth.currentUser.getIdToken(/* forceRefresh */ true);
  return submitRequest(functionUrl + params, {}, 'GET', token);
};

export const getLinks = async (adminUrl, siteName, linkType) => {
  try {
    const response = await isoFetch(
      `${adminUrl}/api/${siteName}/links?type=${linkType}`,
      {
        credentials: 'include',
      }
    );
    return response.json();
  } catch (err) {
    throw new Error(err.message);
  }
};

export const updateLinks = (adminUrl, siteName, links, linkType) => {
  try {
    const url = `${adminUrl}/api/${siteName}/links`;
    return submitRequest(url, { type: linkType, links }, 'PUT');
  } catch (err) {
    throw new Error(err.message);
  }
};

const COUNT_PER_PAGE = 20;
export const getRecipePosts = async (
  adminUrl: string,
  filters: Object = {}
) => {
  const params = {
    status: 1,
    rating: filters.rating,
    sortBy: filters.sortBy,
    sortOrder: filters.sortOrder,
    category: filters.category || 'all',
    page: filters.page,
    count_per_page: COUNT_PER_PAGE,
  };
  if (filters.tag) {
    params.tag = filters.tag;
  }
  if (filters.date) {
    params.date = filters.date;
  }
  if (filters.authorId) {
    params.author = filters.authorId;
  }
  if (filters.keywords) {
    params.keywords = filters.keywords;
  }

  const query = 'isRecipe=1&' + queryBuilder(params);
  const url = `${adminUrl}/posts?${query}`;

  const postsData = await submitRequest(url, {}, 'GET');
  const recipes = postsData.posts || [];

  return {
    recipes,
    hasMore: recipes.length === COUNT_PER_PAGE,
  };
};

type ReceiveVideos = (videos: Array<Video>) => void;

export const getVideos = (id: string, receiveVideos: ReceiveVideos): any =>
  base.listenTo(`product_videos/${id}`, {
    context: {},
    asArray: true,
    then(data) {
      receiveVideos(data);
    },
  });

export const updateFirebaseOnPostSave = async (
  postName,
  currentUser,
  isReindexScheduled = false,
  categoryNicename,
  reindexedNames
) => {
  let extraAttributes = {};
  if (isReindexScheduled) {
    const reindexedCount = reindexedNames.length;
    reindexedNames[reindexedCount - 1] = {
      ...reindexedNames[reindexedCount - 1],
      category_nicename: categoryNicename,
    };
    extraAttributes = {
      reindexedNames,
    };
  }

  await updatePostInFirebase(postName, {
    lastUpdatedAt: getCurrentUtcTime(), // to trigger version update
    productsCreated: 0,
    isContentUpdated: false,
    lastUpdatedBy: currentUser,
    ...extraAttributes,
  });
};

export const getVideoFooter = async (adminUrl, siteName) => {
  try {
    const response = await isoFetch(`${adminUrl}/api/${siteName}/videofooter`, {
      credentials: 'include',
    });

    if (response.status >= 400) {
      throw response;
    }

    const videoFooter = await response.json();

    const mappedVideoFooter = {
      isActive: videoFooter?.is_visible > 0 ? true : false,
      sectionTitle: videoFooter?.section_title || '',
      videoUrl: videoFooter?.video_url || '',
      videoTitle: videoFooter?.video_title || '',
      videoDescription: videoFooter?.video_description || '',
      videoThumbnail: videoFooter?.video_thumbnail || '',
      videoType: videoFooter?.video_type || '',
      totalViews: videoFooter?.total_views || 0,
      duration: videoFooter?.duration || 0,
    };
    return mappedVideoFooter;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const updateVideoFooter = (adminUrl, siteName, videoFooter) => {
  const mappedVideoFooter = {
    is_visible: videoFooter?.isActive,
    section_title: videoFooter?.sectionTitle,
    video_url: videoFooter?.videoUrl,
    video_title: videoFooter?.videoTitle,
    video_description: videoFooter?.videoDescription,
    video_thumbnail: videoFooter?.videoThumbnail,
    video_type: videoFooter?.videoType,
    total_views: videoFooter?.totalViews,
    duration: videoFooter?.duration,
  };

  try {
    const url = `${adminUrl}/api/${siteName}/videofooter`;
    return submitRequest(url, mappedVideoFooter, 'PUT');
  } catch (err) {
    throw new Error(err.message);
  }
};
