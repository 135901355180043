// @flow
import * as React from 'react';
import grey from '@material-ui/core/colors/grey';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import BlockSharpIcon from '@material-ui/icons/BlockSharp';
import {
  Error,
  Delete,
  Lock,
  Edit,
  Info,
  Star,
  CheckCircle,
  FormatAlignRight,
  FormatAlignLeft,
  ViewColumn,
  Palette,
  FormatColorReset,
  Search,
  InsertPhoto,
  Create,
  FileCopy,
  PlayCircleOutline,
  PlayCircleFilled,
  BrokenImage,
  GridOn,
  Opacity,
  Close,
  Apps,
  ArrowDropUp,
  ArrowDropDown,
  ArrowRight,
  Save,
  Check,
  Add,
  LibraryAdd,
  ImportExport,
  Loop,
  ArrowBack,
  Replay,
  Repeat,
  Schedule,
  BatteryChargingFull,
  Visibility,
  Fingerprint,
  VolumeUp,
  CameraAlt,
  ArrowUpward,
  ThumbUp,
  ThumbDown,
  ChevronRight,
  Cached,
  CheckBoxOutlineBlank,
  Settings,
  Code,
  Clear,
  FormatIndentIncrease,
  StarHalf,
  Launch,
  PlayArrow,
  Pause,
  LockOpen,
  ArrowForward,
  Shuffle,
  CloudDownload,
  CloudUpload,
  Apple,
  Autorenew,
  Event,
} from '@material-ui/icons';

import style from './icons.module.css';

const white = '#ffffff';

type DefaultProps = {
  icon: Object,
  fontSize?: 16 | 24,
  color?: string,
  htmlColor?: string,
};

type Props = {
  fontSize?: 'small' | 'large' | 'inherit' | 'medium',
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'error' | 'disabled',
  onClick?: Function,
  disabled?: boolean,
};

export const DefaultIcon = ({
  icon,
  fontSize = 16,
  ...rest
}: DefaultProps): React.Node => {
  const Icon = icon;
  // $FlowFixMe: suppressing this error until we can refactor
  return <Icon style={{ fontSize }} {...rest} />;
};

export const DraftIcon = ({ fontSize = 'inherit' }: Props): React.Node => (
  <BlockSharpIcon htmlColor={'#C52100'} fontSize={fontSize} />
);

export const LockIcon = (props: Props): React.Node => <Lock {...props} />;

export const AddIcon = (props: Props): React.Node => <Add {...props} />;

export const ParallaxIcon = (props: Props): React.Node => (
  <ImportExport {...props} />
);

export const InfoIcon = ({ fontSize = 'inherit' }: Props): React.Node => (
  <Info fontSize={fontSize} color="inherit" />
);

export const CheckCircleIcon = (): React.Node => (
  <CheckCircle fontSize="inherit" color="inherit" />
);

export const CheckIcon = (): React.Node => (
  <Check fontSize="inherit" color="inherit" />
);

export const EditIcon = (): React.Node => <Edit fontSize="inherit" />;

export const FormatAlignRightIcon = (props: Props): React.Node => (
  <FormatAlignRight {...props} />
);

export const GridIcon = (): React.Node => <Apps />;

export const FormatAlignLeftIcon = (props: Props): React.Node => (
  <FormatAlignLeft {...props} />
);

export const SearchIcon = (): React.Node => <Search />;

export const SaveIcon = (): React.Node => <Save />;

export const ArrowDropUpIcon = (): React.Node => (
  <ArrowDropUp fontSize="inherit" />
);

export const ArrowDropDownIcon = (): React.Node => (
  <ArrowDropDown fontSize="inherit" />
);

export const ArrowRightIcon = (): React.Node => (
  <ArrowRight fontSize="inherit" />
);

export const EqualWidthColumnIcon = (props: Props): React.Node => (
  <ViewColumn {...props} />
);

export const InsertPhotoIcon = (props: Props): React.Node => (
  <InsertPhoto {...props} />
);

export const DesignModeIcon = ({
  htmlColor = white,
}: {
  htmlColor?: string,
}): React.Node => (
  <Palette color="inherit" htmlColor={htmlColor} fontSize="inherit" />
);

export const ErrorIcon = (): React.Node => (
  <Error className={style.buttonLeftIcon} fontSize="inherit" />
);

export const DeleteIcon = ({
  fontSize = 'inherit',
  disabled = false,
}: Props): React.Node => (
  <Delete htmlColor={disabled ? '#9e9e9e' : '#C52100'} fontSize={fontSize} />
);

export const DefaultDeleteIcon = ({ color = 'inherit' }: Props): React.Node => (
  <Delete color={color} />
);

const starIconTheme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        '&:hover': {
          '@media(min-width:1100px)': {
            color: 'inherit',
          },
        },
      },
    },
  },
});

export const StarIcon = (props: Props): React.Node => (
  <MuiThemeProvider theme={starIconTheme}>
    <Star {...props} />
  </MuiThemeProvider>
);

export const RemoveColorIcon = (): React.Node => <FormatColorReset />;

export const CreateIcon = (): React.Node => <Create />;

export const CopyIcon = (): React.Node => <FileCopy fontSize="inherit" />;

export const PlayCircleOutlineIcon = (props: Props): React.Node => (
  <PlayCircleOutline {...props} />
);

export const PlayCircleFilledIcon = (props: Props): React.Node => (
  <PlayCircleFilled {...props} />
);

export const BrokenImageIcon = (): React.Node => <BrokenImage />;

export const RepeatImageIcon = (): React.Node => <GridOn />;

export const OpacityIcon = (): React.Node => <Opacity />;

export const ArrowRightSharpIcon = (props: Props): React.Node => (
  <KeyboardArrowRightSharpIcon {...props} />
);

export const CloseIcon = (props: Props): React.Node => <Close {...props} />;

export const PasteIcon = (props: Props): React.Node => (
  <LibraryAdd {...props} />
);

export const LoopIcon = (props: Props): React.Node => <Loop {...props} />;

export const ArrowBackIcon = (props: Props): React.Node => (
  <ArrowBack {...props} />
);
export const ArrowForwardIcon = (props: Props): React.Node => (
  <ArrowForward {...props} />
);

export const RepublishIcon = (props: Props): React.Node => (
  <Replay {...props} />
);

export const RepostIcon = (props: Props): React.Node => <Repeat {...props} />;

export const ScheduleIcon = (props: Props): React.Node => (
  <Schedule {...props} />
);

export const BatteryChargingFullIcon = (props: Props): React.Node => (
  <BatteryChargingFull {...props} />
);
export const VisibilityIcon = (props: Props): React.Node => (
  <Visibility {...props} />
);
export const FingerprintIcon = (props: Props): React.Node => (
  <Fingerprint {...props} />
);
export const VolumeUpIcon = (props: Props): React.Node => (
  <VolumeUp {...props} />
);
export const CameraAltIcon = (props: Props): React.Node => (
  <CameraAlt {...props} />
);
export const ArrowUpwardIcon = (props: Props): React.Node => (
  <ArrowUpward {...props} />
);
export const ThumbUpIcon = (props: Props): React.Node => <ThumbUp {...props} />;

export const ThumbDownIcon = (props: Props): React.Node => (
  <ThumbDown {...props} />
);

export const ChevronRightIcon = (props: Props): React.Node => (
  <ChevronRight {...props} />
);

export const CachedIcon = (props: Props): React.Node => <Cached {...props} />;

export const CheckBoxOutlineBlankIcon = (props: Props): React.Node => (
  <CheckBoxOutlineBlank {...props} htmlColor={grey[900]} />
);

export const SettingsIcon = (props: Props): React.Node => (
  <Settings {...props} />
);

export const CodeIcon = (props: Props): React.Node => <Code {...props} />;

export const ClearIcon = (props: Props): React.Node => <Clear {...props} />;
export const FormatIndentIncreaseIcon = (props: Props): React.Node => (
  <FormatIndentIncrease {...props} />
);
export const StarHalfIcon = (props: Props): React.Node => (
  <StarHalf {...props} />
);
export const LaunchIcon = (props: Props): React.Node => <Launch {...props} />;
export const PlayArrowIcon = (props: Props): React.Node => (
  <PlayArrow {...props} />
);
export const PauseIcon = (props: Props): React.Node => <Pause {...props} />;
export const LockOpenIcon = (props: Props): React.Node => (
  <LockOpen {...props} />
);
export const ShuffleIcon = (props: Props): React.Node => <Shuffle {...props} />;
export const UploadIcon = (props: Props): React.Node => (
  <CloudUpload {...props} />
);
export const DownloadIcon = (props: Props): React.Node => (
  <CloudDownload {...props} />
);
export const AppleIcon = (props: Props): React.Node => <Apple {...props} />;

export const RefurbishIcon = (props: Props): React.Node => (
  <Autorenew {...props} />
);

export const EventIcon = (props: Props): React.Node => <Event {...props} />;
